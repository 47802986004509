import React, { ReactNode } from 'react';
import classnames from 'classnames';

import FormError from '@components/shared/Form/Error/FormError';
import HelpText from '@components/shared/Form/HelpText/HelpText';

export default function Textarea({
  className,
  name,
  label,
  title,
  helpText,
  error,
  customRender,
  ...props
}: {
  className?: string;
  name: string;
  label?: string;
  title?: string;
  helpText?: string;
  error?: any;
  customRender: () => ReactNode;
}) {
  const wrapperClassName = classnames('form-textarea', className, {
    'form-textarea--error': error
  });
  return (
    <div className={wrapperClassName}>
      {title && <h4>{title}</h4>}
      <div className="form-textarea__wrapper">
        {label && (
          <label className="form-textarea__label" htmlFor={name}>
            {label}
          </label>
        )}
        {customRender && customRender()}
        {!customRender && <textarea className="form-textarea__field" {...props} />}
      </div>
      <HelpText className="form-textarea__help-text" text={helpText} />
      <FormError error={error} />
    </div>
  );
}
