import React from 'react';
import pluralize from 'pluralize';
import isNumber from 'lodash/isNumber';
import PropTypes from 'prop-types';

import Input from '@components/shared/Form/Input';
import Icon from '@components/shared/Icon/Icon';

const NumberPicker = ({
  // eslint-disable-next-line no-unused-vars
  type,
  value,
  itemLabel,
  onChange,
  min,
  max,
  ...props
}) => {
  const inputValue = `${value} ${pluralize(itemLabel, value)}`;

  const handleChange = (value) => {
    onChange({ target: { name: props.name, value } });
  };

  const onIncrement = () => {
    handleChange(value + 1);
  };

  const onDecrement = () => {
    handleChange(value - 1);
  };

  const decrementIsDisabled = isNumber(min) && value - 1 < min;
  const incrementIsDisabled = isNumber(max) && value + 1 > max;

  return (
    <div className="form-number-picker">
      <button
        type="button"
        onClick={onDecrement}
        disabled={decrementIsDisabled}
        className="form-number-picker__button form-number-picker__button--remove"
      >
        <Icon icon="remove" size={20} />
      </button>
      <Input {...props} type="text" labelIsPlaceholder={false} readOnly value={inputValue} />
      <button
        type="button"
        onClick={onIncrement}
        disabled={incrementIsDisabled}
        className="form-number-picker__button form-number-picker__button--add"
      >
        <Icon icon="add" size={20} />
      </button>
    </div>
  );
};

NumberPicker.defaultProps = {
  itemLabel: 'item',
  min: 0
};

NumberPicker.propTypes = {
  value: PropTypes.number,
  itemLabel: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number
};

export default NumberPicker;
