import React from 'react';
import { components as ReactSelectComponents } from 'react-select';

import Icon from '@components/shared/Icon/Icon';

const { ValueContainer, Placeholder } = ReactSelectComponents;

const CustomValueContainer = ({ children, ...props }) => (
  <ValueContainer {...props}>
    <Placeholder {...props} isFocused={props.isFocused}>
      {props.selectProps.placeholder}
    </Placeholder>

    {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
  </ValueContainer>
);

const CustomMultiValueRemove = ({ innerProps }) => (
  <span {...innerProps}>
    <Icon icon="close-circle" size={16} />
  </span>
);

const CustomDropDownIndicator = ({ innerProps }) => <Icon {...innerProps} icon="drop-down" size={24} />;

const CustomClearIndicator = ({ innerProps, clearValue }) => (
  <Icon onClick={clearValue} {...innerProps} icon="close" size={16} style={{ marginRight: '4px', cursor: 'pointer' }} />
);

const CustomLoupeIndicator = ({ innerProps }) => (
  <Icon {...innerProps} icon="loupe" size={24} style={{ cursor: 'pointer' }} />
);
export {
  CustomValueContainer,
  CustomMultiValueRemove,
  CustomDropDownIndicator,
  CustomLoupeIndicator,
  CustomClearIndicator
};
