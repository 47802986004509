import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';

const SwitchMui = ({ field, props, label, value }) => (
  <FormGroup>
    <FormControlLabel control={<Switch disableRipple checked={value} {...field} {...props} />} label={label} />
  </FormGroup>
);

SwitchMui.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default SwitchMui;
