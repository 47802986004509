// const colorPrimaryPurple = '#A200ED';
const colorPrimaryBlack = '#342F41';
// const colorLightPurple = '#F4F2FF';
// const colorGhostPurple = '#F8F8FB';
const colorRed = '#e03a86';
const colorLightGrey = '#F3F1F6';
const colorDarkGrey = '#716F80';

const selectStyles = {
  base: (provided) => ({
    ...provided,
    fontSize: 16
  }),
  container: (provided) => ({
    ...provided
  }),
  control: (provided) => ({
    ...provided,
    paddingRight: 24,
    color: colorDarkGrey
  }),
  multiValue: (provided) => ({
    ...provided,
    borderRadius: 10,
    backgroundColor: colorLightGrey,
    padding: '2px 8px',
    margin: 4
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: 14,
    fontWeight: 600,
    color: colorDarkGrey
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    cursor: 'pointer',
    color: colorPrimaryBlack,
    transition: '0.1s linear all',
    '>svg': {
      width: 18,
      height: 18
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: colorRed
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#342F41',
    fontSize: 16,
    fontWeight: 600,
    top: 'unset',
    transform: 'unset'
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    borderRadius: 10
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 10,
    margin: 0,
    borderRadius: 10,
    boxShadow: '0 1px 6px -3px rgba(0, 0, 0, 0.5)'
  }),
  option: (provided) => ({
    ...provided,
    cursor: 'pointer',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: colorLightGrey,
      color: colorPrimaryBlack,
      fontWeight: 600
    }
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: state.selectProps.isMulti ? '6px 24px' : '20px 24px 5px 24px',
    overflow: 'visible'
  }),
  placeholder: (provided, state) => {
    const isFilled = state.hasValue || state.selectProps.inputValue || state.isFocused;
    const { isMulti } = state.selectProps;
    return {
      ...provided,
      lineHeight: '21px',
      position: 'absolute',
      color: '#342F41',
      fontWeight: isFilled ? 500 : 600,
      top: isFilled ? 12 : '50%',
      left: isMulti ? 38 : 24,
      transition: 'top 0.1s, font-size 0.1s',
      fontSize: isFilled ? 12 : 16
    };
  }
};

export default selectStyles;
