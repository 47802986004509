import React, { useState, useEffect } from 'react';

export default function isCohesionReady() {
  const [isLoading, setIsLoading] = useState(true);
  const [anonymousId, setAnonymousId] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [instanceId, setInstanceId] = useState(null);

  // Wait until Cohesion is loaded on the page
  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 3000);
    if (window && window.cohesion) {
      window.cohesion('ready', () => {
        clearTimeout(timer);
        setIsLoading(false);

        const { anonymousId, sessionId, instanceId } = window._Cohesion;

        setAnonymousId(anonymousId);
        setSessionId(sessionId);
        setInstanceId(instanceId);
      });
    }
  }, []);

  return [isLoading, anonymousId, sessionId, instanceId];
}
