import React from 'react';
import { Field } from 'formik';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Input from '@components/shared/Form/Input';
import Textarea from '@components/shared/Form/Textarea/Textarea';
import Select from '@components/shared/Form/Select/Select';
import Checkbox from '@components/shared/Form/Checkbox';
import RadioButton from '@components/shared/Form/Radio/Radio';
import NumberPicker from '@components/shared/Form/NumberPicker/NumberPicker';
import NumberPickerControl from '@components/shared/Form/NumberPicker/NumberPickerControl';
import Datepicker from '@components/shared/Datepicker/Datepicker';
import PhoneNumberInput from '@components/shared/Form/PhoneNumber/PhoneNumber';
import SwitchMui from '@components/shared/Form/SwitchMui/SwitchMui';

const fieldTypes = {
  checkbox: 'checkbox',
  switch: 'switch',
  radio: 'radio',
  select: 'select',
  textarea: 'textarea',
  text: 'text',
  number: 'number',
  email: 'email',
  password: 'password',
  numberPicker: 'numberPicker',
  numberPickerControl: 'numberPickerControl',
  date: 'date',
  tel: 'tel'
};

const FormikField = ({
  type = undefined,
  required = false,
  variant = 'normal', // normal, fused, fused-first, fused-last
  getSelectValue = undefined,
  onChange = undefined,
  ...props
}) => {
  const renderFormField = () => {
    switch (type) {
      case fieldTypes.switch:
        return SwitchMui;
      case fieldTypes.checkbox:
        return Checkbox;
      case fieldTypes.radio:
        return RadioButton;
      case fieldTypes.select:
        return Select;
      case fieldTypes.textarea:
        return Textarea;
      case fieldTypes.numberPicker:
        return NumberPicker;
      case fieldTypes.numberPickerControl:
        return NumberPickerControl;
      case fieldTypes.date:
        return Datepicker;
      case fieldTypes.tel:
        return PhoneNumberInput;
      default:
        return Input;
    }
  };

  const defaultGetSelectValue = (value) => props.options.find((i) => (i?.value || i) === value);

  const selectValueHandler = getSelectValue ? getSelectValue : defaultGetSelectValue;

  return (
    <Field {...props}>
      {({ field, meta }) => {
        const Component = renderFormField();
        const selectHandler = ({ value }) => {
          field.onChange({ target: { name: field.name, value } });
        };

        const error = meta.touched && meta.error ? meta.error : undefined;
        const componentProps = { ...props };

        if (required) {
          if ('label' in componentProps) {
            componentProps.label = `${componentProps.label}*`;
          }
          if ('placeholder' in componentProps) {
            componentProps.placeholder = `${componentProps.placeholder}*`;
          }
        }

        const getOnChangeHandler = () => {
          if (onChange) {
            return onChange;
          }
          return type === fieldTypes.select ? selectHandler : field.onChange;
        };

        const handleChange = getOnChangeHandler();

        return (
          <div className={getClassNames()}>
            <Component
              field={field}
              type={type}
              {...componentProps}
              {...field}
              value={type === fieldTypes.select ? selectValueHandler(field.value) : field.value}
              variant={getVariant()}
              onChange={handleChange}
              error={error}
            />
          </div>
        );
      }}
    </Field>
  );

  function getClassNames() {
    return classNames('form-field', {
      'form-field-fused': variant?.includes('fused')
    });
  }

  function getVariant() {
    return variant ?? 'normal';
  }
};

FormikField.propTypes = {
  type: PropTypes.oneOf(Object.values(fieldTypes)),
  getSelectValue: PropTypes.func
};

export default FormikField;
