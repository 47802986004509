import React, { forwardRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import FormError from '@components/shared/Form/Error/FormError';
import Icon from '@components/shared/Icon/Icon';

const Input = forwardRef(function Input(
  { label, name, icon, className, variant, type, error, labelIsPlaceholder, placeholder, ...props },
  ref
) {
  const [showValue, changeShowStatus] = useState(false);

  const wrapperClassNames = classnames('form-input', className, {
    'form-input--labeled': labelIsPlaceholder,
    'form-input--error': error
  });

  const inputClassNames = classnames('form-input__input', {
    'form-input__input__fused': variant?.includes('fused'),
    'form-input__input__fused--first': variant?.includes('fused') && variant?.includes('first'),
    'form-input__input__fused--last': variant?.includes('fused') && variant?.includes('last')
  });

  const labelClassNames = (value) =>
    classnames('form-input__label', {
      'form-input__label--placeholder': labelIsPlaceholder,
      'form-input__label--placeholder-filled': value && value.length
    });

  const isPasswordInput = type === 'password';
  const passwordInputType = showValue ? 'text' : 'password';
  const toggleValueVisibility = () => {
    changeShowStatus(!showValue);
  };

  return (
    <div className={wrapperClassNames}>
      <div className="form-input__wrapper">
        {icon && icon()}
        <input
          placeholder={placeholder}
          className={inputClassNames}
          name={name}
          id={name}
          type={isPasswordInput ? passwordInputType : type}
          ref={ref}
          {...props}
        />

        {label && (
          <label className={labelClassNames(props.value)} htmlFor={name}>
            {label}
          </label>
        )}

        {isPasswordInput && (
          <button
            tabIndex={-1}
            title="Toggle password visible or hidden"
            type="button"
            onClick={toggleValueVisibility}
            className="form-input__password-toggle"
          >
            <Icon icon={showValue ? 'eye-off' : 'eye'} size={24} />
          </button>
        )}
      </div>
      {variant?.includes('fused') || <FormError className="form-input__error" error={error} />}
    </div>
  );
});

Input.defaultProps = {
  value: '',
  type: 'text',
  onChange: () => void 0,
  labelIsPlaceholder: true,
  placeholder: ''
};

Input.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.func,
  className: PropTypes.string,
  labelIsPlaceholder: PropTypes.bool,
  placeholder: PropTypes.string
};

export default Input;
