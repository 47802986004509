import React, { forwardRef } from 'react';
import dayjs from 'dayjs';

import Icon from '@components/shared/Icon/Icon';
import Input from '@components/shared/Form/Input';
import YearPicker from '@components/shared/Datepicker/YearPicker/YearPicker';

// eslint-disable-next-line react/display-name,no-unused-vars
const DatePickerInput = forwardRef(({ onClick, ...props }, ref) => (
  <div className="form-datepicker__input" onClick={onClick}>
    <Input
      icon={() => <Icon className="form-datepicker__icon" icon="icon_24_date_picker" size={24} />}
      type="text"
      {...props}
      autoComplete="off"
    />
  </div>
));

const CustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  changeYear
}) => {
  const selectedMonth = dayjs(date).format('MMM');
  const selectedYear = dayjs(date).format('YYYY');
  return (
    <div className="form-datepicker__header row between-xs middle-xs">
      <button
        className="form-datepicker__navigation"
        disabled={prevMonthButtonDisabled}
        onClick={decreaseMonth}
        type="button"
      >
        <Icon icon="arrow-left" size={24} />
      </button>
      <div>
        {selectedMonth}{' '}
        <YearPicker selected={selectedYear} onSelect={changeYear}>
          {selectedYear}
        </YearPicker>
      </div>
      <button
        className="form-datepicker__navigation"
        disabled={nextMonthButtonDisabled}
        onClick={increaseMonth}
        type="button"
      >
        <Icon icon="arrow-right" size={24} />
      </button>
    </div>
  );
};

export { CustomHeader, DatePickerInput };
