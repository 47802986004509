import React from 'react';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { DatePickerInput, CustomHeader } from '@components/shared/Datepicker/components';

const Datepicker = ({ onChange, value, className, error, label, placeholder, labelIsPlaceholder, ...props }) => {
  const handleChange = (value) => {
    onChange({
      target: {
        name: props.name,
        value
      }
    });
  };

  return (
    <div className={classnames('form-datepicker', className)}>
      <DatePicker
        {...props}
        renderCustomHeader={(props) => <CustomHeader {...props} />}
        selected={value}
        showPopperArrow={false}
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
        dateFormat="MM/dd/yyyy"
        popperPlacement="bottom-start"
        popperModifiers={{
          flip: {
            behavior: ['bottom'] // don't allow it to flip to be above
          }
        }}
        customInput={
          <DatePickerInput
            name={props.name}
            label={label}
            placeholder={placeholder}
            labelIsPlaceholder={labelIsPlaceholder}
            error={error}
          />
        }
        onChange={handleChange}
      />
    </div>
  );
};

export default Datepicker;
