import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const HelpText = ({ text, className, ...props }) => {
  if (!text) {
    return null;
  }
  return (
    <div className={classnames('form-help-text', className)} {...props}>
      {text}
    </div>
  );
};

HelpText.propTypes = {
  text: PropTypes.string
};

export default HelpText;
