import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const FormError = ({ error, className = undefined, centered = false, ...props }) => {
  if (!error) {
    return null;
  }
  return (
    <div
      className={classnames('form-error', className, {
        'form-error--centered': centered
      })}
      {...props}
    >
      {error}
    </div>
  );
};

FormError.propTypes = {
  error: PropTypes.any,
  centered: PropTypes.bool
};

export default FormError;
