import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

// eslint-disable-next-line no-unused-vars
const Checkbox = ({ label, value, className, error, disabled, ...props }) => {
  const wrapperClassName = classnames('form-checkbox', className, {
    'form-checkbox--disabled': disabled
  });
  return (
    <div className={wrapperClassName} key={value}>
      <label className="form-checkbox__wrapper">
        {label ? label : ''}
        <input className="form-checkbox__input" type="checkbox" checked={value} disabled={disabled} {...props} />
        <span className="form-checkbox__mark" />
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string
};

export default Checkbox;
