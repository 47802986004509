import React, { memo } from 'react';
import Link from 'next/link';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Button = ({
  children = undefined,
  className = undefined,
  type = 'button',
  disabled = false,
  onClick = undefined,
  href = undefined,
  as = undefined,
  size = 'md',
  variant = 'primary',
  fluid = false,
  icon = false,
  circle = false,
  ...props
}) => {
  const Element = href && !disabled ? 'a' : 'button';

  const Component = (
    <Element
      className={classnames('btn', className, {
        [`btn--${size}`]: size,
        [`btn--${variant}`]: variant,
        'btn--circle': circle,
        'btn--icon': icon,
        'btn--fluid': fluid
      })}
      type={type}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {children && <div className="btn__inner">{children}</div>}
      {icon && icon}
    </Element>
  );

  if (href && !disabled) {
    return (
      <Link href={href} as={as}>
        {Component}
      </Link>
    );
  }

  return Component;
};

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  fluid: PropTypes.bool,
  icon: PropTypes.any,
  circle: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  size: PropTypes.oneOf(['sm', 'md']),
  variant: PropTypes.oneOf(['primary', 'outline', 'text', 'link']),
  disabled: PropTypes.bool
};

export default memo(Button);
