import React, { forwardRef } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import Input from '@components/shared/Form/Input';

const CustomPhoneNumberInput = forwardRef(function CustomInput(props, ref) {
  return <Input ref={ref} {...props} />;
});

const PhoneNumberInput = ({ onChange, ...props }) => {
  const handleChange = (value) => {
    onChange({ target: { name: props.name, value } });
  };

  return (
    <div className="form-input form-input-phone">
      <PhoneInput defaultCountry="US" inputComponent={CustomPhoneNumberInput} onChange={handleChange} {...props} />
    </div>
  );
};

export default PhoneNumberInput;
