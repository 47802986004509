import * as Yup from 'yup';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

const Validators = {
  email: () => Yup.string().email('Please enter a valid email.'),
  password: () => Yup.string().min(8),
  airportCode: () => Yup.string().min(3).max(4),
  firstName: () => Yup.string().max(30),
  lastName: () => Yup.string().max(30),
  phoneNumber: () =>
    Yup.string().test('is-valid-phone-number', 'Please enter a valid phone number', (value) => {
      if (!value) {
        return true;
      }
      return isPossiblePhoneNumber(value);
    }),
  fieldConfirm: (ref, errorMessage = 'Fields are not matched') => Yup.string().oneOf([Yup.ref(ref), ''], errorMessage),
  booleanTrue: (message = 'Field must be checked') => Yup.bool().oneOf([true], message)
};

export default Validators;
