import React from 'react';
import pluralize from 'pluralize';
import isNumber from 'lodash/isNumber';
import PropTypes from 'prop-types';

import Input from '@components/shared/Form/Input';
import Icon from '@components/shared/Icon/Icon';

const NumberPickerControl = ({
  // eslint-disable-next-line no-unused-vars
  type,
  value,
  itemLabel,
  onChange,
  min,
  max,
  customLabel,
  customLegend,
  ...props
}) => {
  // const inputValue = `${value}`;
  const inputValue = `${value} ${pluralize(itemLabel, value)}`;

  const handleChange = (value) => {
    onChange({ target: { name: props.name, value } });
  };

  const onIncrement = () => {
    handleChange(value + 1);
  };

  const onDecrement = () => {
    handleChange(value - 1);
  };

  const decrementIsDisabled = isNumber(min) && value - 1 < min;
  const incrementIsDisabled = isNumber(max) && value + 1 > max;

  return (
    <div className="form-number-picker-control">
      <div className="form-number-picker-control__wrapper">
        <Input {...props} type="text" labelIsPlaceholder={false} readOnly value={inputValue} />
        <span className="custom-label">{customLabel}</span>
        <button
          type="button"
          onClick={onIncrement}
          disabled={incrementIsDisabled}
          className="form-number-picker-control__button form-number-picker-control__button--add"
        >
          <Icon icon="add" size={20} />
        </button>
        <button
          type="button"
          onClick={onDecrement}
          disabled={decrementIsDisabled}
          className="form-number-picker-control__button form-number-picker-control__button--remove"
        >
          <Icon icon="remove" size={20} />
        </button>
        <span className="custom-legend">{customLegend}</span>
      </div>
    </div>
  );
};

NumberPickerControl.defaultProps = {
  min: 0
};

NumberPickerControl.propTypes = {
  value: PropTypes.number,
  itemLabel: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number
};

export default NumberPickerControl;
