import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import HelpText from '@components/shared/Form/HelpText/HelpText';

const RadioButton = ({
  label = undefined,
  value = undefined,
  radioValue = undefined,
  className = undefined,
  // eslint-disable-next-line no-unused-vars
  error = undefined,
  helpText = undefined,
  onChange = undefined,
  name,
  ...props
}) => {
  const wrapperClassName = classnames('form-radio', className);
  return (
    <div className={wrapperClassName}>
      <label className="form-radio__wrapper">
        {label && label}
        <input
          className="form-radio__input"
          type="radio"
          checked={value === radioValue}
          name={name}
          {...props}
          onChange={() => onChange({ target: { name, value: radioValue } })}
        />
        <span className="form-radio__mark" />
      </label>
      <HelpText text={helpText} />
    </div>
  );
};

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.any,
  helpText: PropTypes.any
};

export default RadioButton;
